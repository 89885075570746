.paper {
  margin: 16px;
  margin-top: 0px;
  background-color: #ffffff;
  color: #000000;
  min-height: calc(100% - 132px);
  width: calc(100% - 48px);
  padding: 8px;
  border: 1px solid #000000;
  line-height: 1.5;
  border-radius: 5px;
  font-family: 'Roboto Mono', monospace;
  font-size: 0.8rem;
  color: #3c3c3b;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 1.6rem;
  font-weight: bold;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.address {
  display: flex;
  flex-direction: column;
}

.data {
  display: flex;
  flex-direction: column;
}

.data > div {
  display: flex;
}

.data > div > div {
  width: 136px;
  font-weight: bold;
}

.data > div > div:nth-child(2) {
  width: auto;
  font-weight: normal;
}

.number div:nth-child(2) {
}

.notes {
  width: fit-content;
  text-align: right;
  max-width: 40vw;
}

.notes-title {
  font-weight: bold;
}

.noteline {
  padding: 4px;
}

.hr-top {
  margin-top: 32px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid #3c3c3b;
}

.positions {
  margin-top: 16px;
  margin-left: -4px;
  width: 100%;
  border-spacing: 0px;
}


.positions .tableheader {
  font-weight: bold;
  color: #3c3c3b;
;
}

.positions td {
  border-bottom: 1px solid #3c3c3b;
}

.positions th:first-child {
  border-top-left-radius: 5px;
}

.positions th:last-child {
  border-top-right-radius: 5px;
}
.positions tr {
  text-align: right;
}


.positions th, .positions td {
  padding: 4px;
  vertical-align: top;
}

.positions th {
  padding-top: 8px;
  padding-bottom: 8px;
}

.positions tr:nth-child(odd) td {
  background-color: #F5F5F5;
}

.positions tr:last-child td {
  padding-bottom: 32px;
}

.positions tr:hover td {
  background-color: #E0E0E0;
}

.positions tr.selected td {
  background-color: #DCE775;
}

.positions tr.selected:nth-child(odd) td {
  background-color: #9CCC65;
}

.positions tr.selected:hover td {
  background-color: #8BC34A;
}



.positions .description {
  text-align: left;
  max-width: 30vw;
}

.total {
  margin-top: 16px;
  width: 100%;
}

.total tr {
  text-align: center;
}

.total td {
  font-size: 0.9rem;
}

.total .brutto, .total .payable {
  font-weight: bold;
}

.payment {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.payment > div, .payments > div {
  display: flex;
}
.payments > div {
  flex-direction: row;
}

.payment > div > div {
  width: 130px;
  font-weight: bold;
}


.payments > div > div{
  min-width: 130px;
  font-weight: bold;
}


.payment > div > div:nth-child(2),
.payments > div > div:nth-child(2) {
  width: auto;
  font-weight: normal;
}

.allowancecharge, .logisticsservicecharge {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.allowancecharge > div, .logisticsservicecharge > div {
  min-width: 130px;
}

.allowancecharge > div:nth-child(2), .logisticsservicecharge > div:nth-child(2) {
  font-weight: bold;
  width: auto;
}

.taxexemptionreason {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.taxexemptionreason > div {
  min-width: 130px;
}

.taxexemptionreason > div:nth-child(2) {
  font-weight: bold;
  width: auto;
}

.footer {
  margin-top: 24px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid #E0E0E0;
  font-weight: bold;
}

.document {
  margin-top: 0px;
  font-weight: normal;
}

.fileuploader {
  width: 100%;
  max-width: calc(100% - 56px) !important;
  margin-top: 16px;
  margin-left: 16px;
}

.customer .address-line {
}

.customer-tab {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.customer-tab div {
  margin-right: 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid lightgray;
  font-weight: bold;
  cursor: pointer;
}

.customer-tab.address .tab-address {
  border-bottom: 2px solid orange;
}

.customer-tab.delivery .tab-delivery {
  border-bottom: 2px solid orange;
}

.customer-tab.tax .tab-tax {
  border-bottom: 2px solid orange;
}

.customer-tab.payee .tab-payee {
  border-bottom: 2px solid orange;
}

.documentname {
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 16px;
}

.error-message {
  position: relative;
  background-color: #C62828;
  color: white;
  width: 50%;
  line-height: 32px;
  left: 25%;
  top: 16px;
  text-align: center;
  padding: 16px;
  font-size: 20px;
  overflow: hidden;
  border-radius: 10px;
}

.invoice-id {
  margin-top: 16px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 124px);
  color: #fff;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid orange;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.pdficon {
  position: relative;
  top: 10px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;
}

.supplier {
  text-align: right;
}
.suppliername {
  font-size: 1rem;
  font-weight: bold;
}
.logo {
  position: relative;
  height: 48px;
}
